import { Alchemy, Network, Nft } from "alchemy-sdk";
import {
  EYEVERSE_CONTRACTS_ARRAY,
  EYE_CONTRACT_ADDRESS,
  NEW_STAKING_CONTRACT,
  OLD_STAKING_CONTRACT,
  REINCARNATED_CONTRACT_ADDRESS,
  owner,
} from "../constants";
import { ethers } from "ethers";
import { initializeApp } from "firebase/app";
import {
  doc,
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "buidlr.firebaseapp.com",
  projectId: "buidlr",
  storageBucket: "buidlr.appspot.com",
  messagingSenderId: "322905631520",
  appId: "1:322905631520:web:0e7a7e9b06577fec420545",
  measurementId: "G-TM6XWWKV7V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const fetchNFTs = async (account: string) => {
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);
  let nfts: Nft[] = [];
  let pageKey;
  //first run
  const nftsForOwner = await alchemy.nft.getNftsForOwner(account, {
    contractAddresses: EYEVERSE_CONTRACTS_ARRAY,
  });
  pageKey = nftsForOwner?.pageKey;
  const response = await alchemy.nft.getNftMetadataBatch(
    nftsForOwner.ownedNfts.map((nft) => {
      return { contractAddress: nft.contract.address, tokenId: nft.tokenId };
    })
  );
  nfts = response;
  //run until no pagekey is returned
  while (pageKey != null || pageKey != undefined) {
    const nftsForOwner = await alchemy.nft.getNftsForOwner(account, {
      contractAddresses: EYEVERSE_CONTRACTS_ARRAY,
      pageKey: pageKey,
    });
    pageKey = nftsForOwner?.pageKey;
    const response = await alchemy.nft.getNftMetadataBatch(
      nftsForOwner.ownedNfts.map((nft) => {
        return {
          contractAddress: nft.contract.address,
          tokenId: nft.tokenId,
        };
      })
    );
    nfts = nfts.concat(response);
  }
  //those lines will add the nfts that are staked
  //   await getStakedTokens(account, NEW_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });
  //   await getStakedTokens(account, OLD_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });

  return nfts;
};

export const fetchNFTsForContract = async (
  account: string,
  contract: string
) => {
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);
  let nfts: Nft[] = [];
  let pageKey;
  //first run
  const nftsForOwner = await alchemy.nft.getNftsForOwner(account, {
    contractAddresses: [contract],
  });
  pageKey = nftsForOwner?.pageKey;
  const response = await alchemy.nft.getNftMetadataBatch(
    nftsForOwner.ownedNfts.map((nft) => {
      return { contractAddress: nft.contract.address, tokenId: nft.tokenId };
    })
  );
  nfts = response;
  //run until no pagekey is returned
  while (pageKey != null || pageKey != undefined) {
    const nftsForOwner = await alchemy.nft.getNftsForOwner(account, {
      contractAddresses: EYEVERSE_CONTRACTS_ARRAY,
      pageKey: pageKey,
    });
    pageKey = nftsForOwner?.pageKey;
    const response = await alchemy.nft.getNftMetadataBatch(
      nftsForOwner.ownedNfts.map((nft) => {
        return {
          contractAddress: nft.contract.address,
          tokenId: nft.tokenId,
        };
      })
    );
    nfts = nfts.concat(response);
  }
  //those lines will add the nfts that are staked
  //   await getStakedTokens(account, NEW_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });
  //   await getStakedTokens(account, OLD_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });

  return nfts;
};

export async function getStakedTokensForAccount(account: string) {
  let nfts = [];
  await getStakedTokens(account, NEW_STAKING_CONTRACT).then((stakedNfts) => {
    if (stakedNfts?.length > 0) {
      nfts = nfts.concat(stakedNfts);
    }
  });
  //   await getStakedTokens(account, OLD_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });

  return nfts;
}

const minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

export async function getErc20Balance(
  owner_address: string,
  contract_address = EYE_CONTRACT_ADDRESS
) {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const DAI = new ethers.Contract(contract_address, minABI, provider);
  return await DAI.balanceOf(owner_address);
}

async function getENS(address: string) {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  let name: string = await provider.lookupAddress(address);
  return name;
}

const fetchCurrentUser = async (address: string) => {
  const querySnapshot = await getDoc(doc(db, "holders", address));
  let user = querySnapshot.data();
  let newOwner: owner = {
    ens: user?.ens ?? null,
    address: address,
    eyeverseRank: user?.total_eyeverse ?? 0,
    last_visit: user?.last_visit ?? 0,
    username: user?.username ?? user?.ens ?? user?.address ?? null,
    bio: user?.bio ?? null,
    twitterHandle: user?.twitterHandle ?? null,
    pfp: user?.pfp ?? false,
  };
  if (user != null && !Object.hasOwn(user, "ens")) {
    newOwner.ens = await getENS(address);
  }
  // accountsToAdd.push(newOwner);

  // accountsToAdd = accountsToAdd.sort((a, b) => b.eyeverseRank - a.eyeverseRank);
  // dispatch(addAllOwners(accountsToAdd));
  return newOwner;
};

function filterStakedTokens(transactions: any[]) {
  let nfts = [];
  let nftsmap = new Map<string, number>();
  //get all staking transfers
  transactions
    .filter((transaction) => {
      return (
        transaction.to.toLowerCase() === NEW_STAKING_CONTRACT.toLowerCase() ||
        transaction.to.toLowerCase() === OLD_STAKING_CONTRACT.toLowerCase()
      );
    })
    .forEach((transaction) => {
      if (!nftsmap.has(transaction.tokenID)) {
        nftsmap.set(transaction.tokenID, 1);
      } else {
        nftsmap.set(transaction.tokenID, nftsmap.get(transaction.tokenID) + 1);
      }
    });

  //get all unstaking transfers
  transactions
    .filter((transaction) => {
      return (
        transaction.from === NEW_STAKING_CONTRACT ||
        transaction.from === OLD_STAKING_CONTRACT
      );
    })
    .forEach((transaction) => {
      if (!nftsmap.has(transaction.tokenID)) {
        console.log("token unstaked without staking", transaction.tokenID);
      } else {
        nftsmap.set(transaction.tokenID, nftsmap.get(transaction.tokenID) - 1);
      }
    });
  nftsmap.forEach((value, key) => {
    if (value > 0) {
      nfts.push({
        contractAddress: REINCARNATED_CONTRACT_ADDRESS,
        tokenId: key,
      });
    }
  });

  return nfts;
}

export async function getStakedTokens(owner: string, contract: string) {
  let results = [];
  const options = {
    method: "GET",
    ContentType: "application/json",
  };
  const url = `https://api.etherscan.io/api
	?module=account
	&action=tokennfttx
	&address=${owner}
	&startblock=16420652
	&sort=asc
	&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;

  let response = await (await fetch(url, options)).json();
  let stakedNfts = filterStakedTokens(response.result);
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);
  if (stakedNfts.length == 0) {
    return [];
  }
  let fullNfts = [];
  //divide stakedNfts into batches of 100 and run them in getNftMetadataBatch
  for (let i = 0; i < stakedNfts.length; i += 100) {
    let batch = stakedNfts.slice(i, i + 100);
    let response = await alchemy.nft.getNftMetadataBatch(batch);
    fullNfts = fullNfts.concat(response);
  }
  let mappedNfts = fullNfts.map((nft) => {
    return {
      ...nft,
      isStaked: true,
    };
  });

  return mappedNfts;
}

export async function getNumberOfStakedTokens(owner: string) {
  const options = {
    method: "GET",
    ContentType: "application/json",
  };
  const url = `https://api.etherscan.io/api
	?module=account
	&action=tokennfttx
	&address=${owner}
	&startblock=16420652
	&sort=asc
	&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;

  let response = await (await fetch(url, options)).json();
  let stakedNfts = filterStakedTokens(response.result);
  return stakedNfts.length;
}

export function add3Dots(account_address: string, limit: number) {
  var dots = "...";
  if (account_address.length > limit) {
    account_address =
      account_address.substring(0, limit - 6) +
      dots +
      account_address.substring(limit - 2, limit);
  }
  return account_address;
}
