import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContent, collectionData, owner } from "../../../../constants";
import { setShowCollection } from "../../../../redux/helperSlice";
import { collectionLookup } from "../../../../utils/image_placeholder";
import styles from "./nft-showroom.module.css";
import {
  setModalContent,
  setModalData,
  setModalOpen,
} from "../../../../redux/modalSlice";
import { ModalHistory } from "../../../modal/modal-history";
import { RootState } from "../../../../app/store";

type tileProps = {
  contract: collectionData;
  setCollection: (contract: collectionData) => void;
  owner: { ens: string; address: string };
};

const NftCollectionTile: React.FC<tileProps> = ({
  contract,
  owner,
  setCollection,
}) => {
  const dispatch = useDispatch();
  const [img, setImg] = useState("");
  const fetchImage = async () => {
    setImg(contract.collection.image);
  };
  // const currentOwner = useSelector((state: RootState) => state.user.owner);
  useEffect(() => {
    fetchImage();
    //doesn't update label without this
    let x = contract.ownership.tokenCount;
  }, []);

  return (
    <button
      className={styles["nft-collection-tile"]}
      onClick={() => {
        if (!owner) {
          return;
        }
        setCollection(contract);
        dispatch(
          setModalData({
            owner: owner,
            nftCollection: contract,
          })
        );
        dispatch(setModalOpen());
        dispatch(setModalContent(ModalContent.NFT_COLLECTION));
        ModalHistory.push({
          data: { owner: owner, nftCollection: contract },
          content: ModalContent.NFT_COLLECTION,
          isOpen: true,
        });
      }}
    >
      <img
        className={styles["nft-collection-tile-image"]}
        src={img}
        alt="icons"
      ></img>
      <div className={styles["nft-collection-number"]}>
        {contract?.ownership?.tokenCount ?? 0}
      </div>
      <label className={styles["name-label"]}>
        {collectionLookup(contract?.collection?.primaryContract)}
      </label>
    </button>
  );
};

export { NftCollectionTile };
