import { paths } from "@reservoir0x/reservoir-sdk";
import { Web3Button, useWeb3ModalTheme } from "@web3modal/react";
import { Alchemy, Network, Nft } from "alchemy-sdk";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import {
  EYEVERSE_RESERVOIR_COLLECTION_SET,
  owner,
  REINCARNATED_CONTRACT_ADDRESS,
} from "../../constants";
import {
  addCollection,
  addCollections,
  addImage,
  updateCollection,
} from "../../redux/nftCollections";
import { addAllOwners } from "../../redux/nftOwnersSlice";
// Firebase imports
import { initializeApp } from "firebase/app";
import {
  doc,
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { setCollectionValue, setEyeBalance } from "../../redux/helperSlice";
import { addAll, addNew } from "../../redux/nftSlice";
import { RootState } from "../../app/store";
import { setAccount, setCurrentOwner } from "../../redux/accountSlice";
import {
  fetchNFTs,
  getErc20Balance,
  getStakedTokens,
  getStakedTokensForAccount,
} from "../../utils/universal_functions";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useMediaQuery } from "react-responsive";

export const TOKEN_KEY = "refine-auth";

//------------------FIRESTORE---------------------------------

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "buidlr.firebaseapp.com",
  projectId: "buidlr",
  storageBucket: "buidlr.appspot.com",
  messagingSenderId: "322905631520",
  appId: "1:322905631520:web:0e7a7e9b06577fec420545",
  measurementId: "G-TM6XWWKV7V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

function groupObjectsByCollectionName(nfts: Nft[]) {
  return nfts.reduce((acc, obj) => {
    const { tokenId, contract } = obj;
    if (!acc[contract.address]) {
      acc[contract.address] = [];
    }
    acc[contract.address].push({ tokenId });
    return acc;
  }, {});
}

let user_value = 0;

function calculateEyeverseSum(nfts: Nft[]) {
  let sum = 0;
  //write some function that takes different values, $EYE, nfts etc into account
  return nfts.length;
}

const addUserToFirestore = async (address: string, nfts: Nft[]) => {
  try {
    const docRef = await setDoc(
      doc(db, "buidlr-web", "holders"),
      {
        [address]: {
          address: address,
          last_visit: Date.now(),
          nfts: groupObjectsByCollectionName(nfts),
          total_eyeverse: calculateEyeverseSum(nfts),
        },
      },
      { merge: true }
    );
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
//------------------ALCHEMY---------------------------------

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API,
  network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);
const providerId = new WalletConnectProvider({
  infuraId: process.env.REACT_APP_INFURA_KEY,
  rpc: {
    1: "https://eth.llamarpc.com",
  },
});
const provider = new ethers.providers.Web3Provider(providerId);
const options = {
  method: "GET",
  headers: {
    accept: "*/*",
    "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
  },
  ContentType: "application/json",
};
const ConnectWalletButton: React.FC = () => {
  let dispatch = useDispatch();
  const { setTheme } = useWeb3ModalTheme();
  // Set modal theme
  setTheme({
    themeMode: "dark",
    themeVariables: {
      "--w3m-font-family": "Roboto, sans-serif",
      "--w3m-accent-color": "#00be60",
      // ...
    },
  });
  // const addressHook = useAccount();
  const account_reset = useSelector((state: RootState) => state.user.address);
  const collections = useSelector(
    (state: RootState) => state.nfts_collections.value
  );
  const { address } = useAccount();

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  // const address = brownbear_eth;
  useEffect(() => {
    if (isMobile) {
      providerId.enable().then(() => {
        //clear the list. otherwise after a refresh the list will be doubled
        dispatch(addAll([]));
        getErc20Balance(address).then((balance) => {
          dispatch(setEyeBalance(balance / 10 ** 18));
        });
        fetchOwners();
        getRemainingCollections();
        if (address?.length > 3) {
          getCollectionsForUser();
          dispatch(setCollectionValue(0));
          fetchNFTs(address).then((nfts) => {
            dispatch(addNew(nfts));
            getStakedTokensForAccount(address).then((stakedNfts) => {
              dispatch(addNew(stakedNfts));

              user_value +=
                stakedNfts.length *
                  collections.find(
                    (collection) =>
                      collection.collection.primaryContract ===
                      REINCARNATED_CONTRACT_ADDRESS
                  )?.collection.floorSale["7day"] ?? 0;
              if (!user_value) user_value = 0;
              dispatch(setCollectionValue(user_value));
              // add number of staked nfts to wonership object in collections
              updateCollectionsWithStakedNfts(stakedNfts);
              addUserToFirestore(address, nfts.concat(stakedNfts));
            });
          });
        }
      });
    } else {
      //clear the list. otherwise after a refresh the list will be doubled
      dispatch(addAll([]));
      getErc20Balance(address).then((balance) => {
        dispatch(setEyeBalance(balance / 10 ** 18));
      });
      fetchOwners();
      getRemainingCollections();
      if (address?.length > 3) {
        getCollectionsForUser();
        dispatch(setCollectionValue(0));
        fetchNFTs(address).then((nfts) => {
          dispatch(addNew(nfts));
          getStakedTokensForAccount(address).then((stakedNfts) => {
            user_value +=
              stakedNfts.length *
                collections.find(
                  (collection) =>
                    collection.collection.primaryContract ===
                    REINCARNATED_CONTRACT_ADDRESS
                )?.collection.floorSale["7day"] ?? 0;
            if (!user_value) user_value = 0;
            dispatch(setCollectionValue(user_value));
            // add number of staked nfts to wonership object in collections
            dispatch(addNew(stakedNfts));
            updateCollectionsWithStakedNfts(stakedNfts);
            addUserToFirestore(address, nfts.concat(stakedNfts));
          });
        });
      }
    }

    // fetchOwners();
  }, [address, account_reset]);

  const fetchOwners = async () => {
    let accountsToAdd: owner[] = [];
    const querySnapshot = await getDoc(doc(db, "buidlr-web", "holders"));
    for (let [address, user] of Object.entries(querySnapshot.data())) {
      let newOwner: owner = {
        ens: user?.ens,
        address: address,
        eyeverseRank: user?.total_eyeverse ?? 0,
        last_visit: user?.last_visit ?? 0,
        username: user?.username ?? null,
        bio: user?.bio ?? "",
        twitterHandle: user?.twitterHandle ?? "",
        pfp: user?.pfp ?? false,
      };
      accountsToAdd.push(newOwner);
    }
    accountsToAdd = accountsToAdd.sort(
      (a, b) => b.eyeverseRank - a.eyeverseRank
    );
    dispatch(addAllOwners(accountsToAdd));
    dispatch(
      setCurrentOwner(accountsToAdd.find((owner) => owner.address === address))
    );
    return accountsToAdd;
  };

  function getRemainingCollections() {
    fetch(
      `https://api.reservoir.tools/collections/v5?collectionsSetId=${EYEVERSE_RESERVOIR_COLLECTION_SET}`,
      options
    )
      .then((response) => response.json())
      .then((res: any) => {
        const response =
          res as paths["/collections/v5"]["get"]["responses"]["200"]["schema"];

        //get ids of owner collections to not overwrite them
        let collectionsIds = collections.map(
          (collection) => collection.collection.id
        );
        //filter to only those collections which are not in  and add them to the store
        response.collections
          .filter(({ id }) => !collectionsIds.includes(id))
          .forEach((collection) => {
            dispatch(
              addCollection({
                collection: collection,
                ownership: {
                  tokenCount: "0",
                  onSaleCount: "0",
                },
              })
            );
          });
      });
  }

  function updateCollectionsWithStakedNfts(stakedNfts: any[]) {
    dispatch(
      updateCollection({
        address: REINCARNATED_CONTRACT_ADDRESS,
        newNfts: stakedNfts.length,
      })
    );
  }

  function getCollectionsForUser() {
    let returnResponse;
    //fetch all eyeverse collections
    //add them to store
    //then fetch user collections and overwrite the ones that are already in the store
    fetch(
      `https://api.reservoir.tools/users/${address}/collections/v3/?collectionsSetId=${EYEVERSE_RESERVOIR_COLLECTION_SET}`,
      options
    )
      .then((response) => response.json())
      .then((res: any) => {
        const response =
          res as paths["/users/{user}/collections/v2"]["get"]["responses"]["200"]["schema"];
        //   "--------------------USER COLLECTIONS-----------------",
        //   response
        // );
        // const filteredResponse = response.collections.filter((contract) =>
        //   EYEVERSE_CONTRACTS_ARRAY.includes(contract.collection.primaryContract)
        // );
        const filteredResponse = response.collections;
        filteredResponse.forEach((collection) => {
          dispatch(addCollection(collection));
        });
        // dispatch(addCollections(filteredResponse));
        let value = 0;
        filteredResponse.forEach((response) => {
          let newValue =
            Number(response.ownership.tokenCount) *
            response.collection.floorSale["7day"];
          if (newValue > 0) {
            value += newValue;
          }
        });
        if (!value) {
          value = 0;
          console.log("error calculating value", value);
        }
        user_value = value;
        dispatch(setCollectionValue(value));
        // calculateCollectionValue(nfts, filteredResponse);
        returnResponse = filteredResponse;
        return filteredResponse;
        // filteredResponse.forEach(fetchImage);
      })
      .catch((err) => console.error(err));
    return returnResponse;
  }

  return <Web3Button />;
};

export { ConnectWalletButton };
