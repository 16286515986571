import React, { useState } from "react";
import styles from "./nft-detail.module.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alchemy, Network } from "alchemy-sdk";
import { Transaction } from "../../constants";
import {
  DividerLine,
  add3Dots,
  collectionLookup,
  getTimeDescription,
  uid,
} from "../../utils/image_placeholder";

export function NftDetailPage() {
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);
  const [nft, setNft] = useState(null);
  const [activity, setActivity] = useState(null);
  // const { collectionId, tokenId } = useParams();
  let collectionId = "0x495f947276749ce646f68ac8c248420045cb7b5e";
  let tokenId = "1";
  const options = {
    method: "GET",
    headers: {
      accept: "*/*",
      "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
    },
    ContentType: "application/json",
  };

  async function getNftActivity() {
    fetch(
      `https://api.reservoir.tools/tokens/${collectionId}%3A${tokenId}/activity/v5`,
      options
    )
      .then((response) => response.json())
      .then((response) => {
        setActivity(response.activities);
      });
  }

  async function getNftDetails() {
    fetch(
      `https://api.reservoir.tools/tokens/v6?tokens=${collectionId}%3A${tokenId}&includeTopBid=true&includeAttributes=true&includeDynamicPricing=true&includeLastSale=true`,
      options
    )
      .then((response) => response.json())
      .then((nft) => {
        setNft(nft.tokens[0]);
      });
    // const nft = await alchemy.nft
    //   .getNftMetadata(collectionId, Number(tokenId), {})
    //   .then((nft) => {
    //     setNft(nft);
    //   });
  }
  useEffect(() => {
    getNftDetails();
    getNftActivity();
  }, []);
  useEffect(() => {}, [nft]);
  //function returning value or dash if value is null
  const valueOrDash = (value: any) => {
    if (value) {
      return value;
    } else {
      return "-";
    }
  };

  const TopLabel = (top, bottom) => {
    return (
      <div className={styles["stats-label"]}>
        <label className={styles["top-stats"]}>{top}</label>
        <label className={styles["bottom-stats"]}>{bottom}</label>
      </div>
    );
  };
  //function returning a div with box style, containing two divs inside with classname stats-label
  const AttributeContainer = (type, value, rarity) => {
    return (
      <div className={styles["box"]}>
        <div className={styles["stats-label"]}>
          <label className={styles["top-stats"]}>{type}</label>
          <label className={styles["bottom-stats"]}>{value}</label>
        </div>
        <div className={styles["stats-label"]}>
          <label className={styles["top-stats"]}>Rarity</label>
          <label className={styles["bottom-stats"]}>{rarity}</label>
        </div>
      </div>
    );
  };

  function generateRow(activity: any) {
    switch (activity.type) {
      case "sale":
        return (
          // <tr>
          //   <td>
          //     <div className={styles["green-text"]}>Sale </div>
          //   </td>
          //   <td>{activity.token.tokenName}</td>
          //   <td>{activity?.price.amount.decimal}</td>
          //   <td>
          //     <div className={styles["grey-text"]}>
          //       {getTimeDescription(activity.timestamp)}
          //     </div>
          //   </td>
          // </tr>
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Sale </div>

            {/* <NftThumbnail address={activity.contract} /> */}
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );

      case "ask":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Ask </div>

            {/* <NftThumbnail address={activity.contract} /> */}
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "transfer":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Transfer </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>-</div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "mint":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Mint </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
      case "bid":
        return (
          <div className={styles["row"]} key={uid()}>
            <div className={styles["green-text"]}>Bid </div>
            <div className={styles["middle"]}> {activity?.token.tokenName}</div>
            <div className={styles["middle"]}>
              {activity?.price.amount.decimal}
            </div>
            <div className={styles["grey-text"]}>
              {getTimeDescription(activity.timestamp)}
            </div>
          </div>
        );
    }

    return (
      <div className={styles["row"]} key={uid()}>
        UNKNOWN ACTIVITY
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["left-side"]}>
        <div className={styles["left-label"]}>
          <label>{nft?.token?.name}</label>
          <label>Owner: {nft?.token?.owner}</label>
        </div>
        <div className={styles["image"]}>
          <img src={nft?.token?.image} alt="Image" />
        </div>
        {/* <div className={styles["polaroid"]}>
          <img src={nft?.token?.image} alt="nftImage" />
          <div className={styles["container"]}>
            <p>{nft?.token?.name}</p>
          </div>
        </div> */}
      </div>
      <div className={styles["right-side"]}>
        <div className={styles["section"]}>
          <div className={styles["labels"]}>
            <div className={styles["top-label"]}>
              {TopLabel(
                "Price",
                valueOrDash(nft?.market?.floorAsk?.price?.amount.decimal)
              )}
            </div>
            <div className={styles["top-label"]}>
              {TopLabel(
                "Last Sale",
                valueOrDash(nft?.token?.lastSale?.price?.amount.decimal)
              )}
            </div>
            <div className={styles["top-label"]}>
              {TopLabel(
                "Top Bid",
                valueOrDash(nft?.market?.topBid?.price?.amount.decimal)
              )}
            </div>
            <div className={styles["top-label"]}>
              {TopLabel("Rarity", valueOrDash(nft?.token?.rarityRank))}
            </div>
            <div className={styles["top-label"]}>
              {TopLabel(
                "Collection Floor",
                valueOrDash(nft?.token?.lastSale?.price?.amount.decimal)
              )}
            </div>
          </div>
        </div>
        <div className={styles["section"]}>
          <div className={styles["flex-container"]}>
            {nft?.token?.attributes.map((attribute) =>
              AttributeContainer(
                attribute.key,
                attribute.value,
                attribute.tokenCount
              )
            )}
          </div>
        </div>
        <div className={styles["section-bottom"]}>
          <h2>Transaction History</h2>
          <div className={styles["row"]} key={uid()}>
            <div className={styles["header"]}>Type </div>
            <div className={styles["header"]}>Name </div>
            <div className={styles["header"]}>Price </div>
            <div className={styles["header"]}>Time </div>
          </div>
          <div className={styles["scrollable"]}>
            <ul>{activity?.map(generateRow)}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
