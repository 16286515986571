import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { owner } from "../../constants";
import styles from "./profile-settings.module.css";
import {
  ImageFromUrl,
  ImagePlaceHolder,
  getOwnerProfilePicture,
} from "../../utils/image_placeholder";
import { MetaMaskAvatar } from "react-metamask-avatar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import CircleLoader from "react-spinners/CircleLoader";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { setModalClose } from "../../redux/modalSlice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Resizer from "react-image-file-resizer";
import { RiCloseCircleFill } from "react-icons/ri";
import modalStyles from "../modal/modal-styles.module.css";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "buidlr.firebaseapp.com",
  projectId: "buidlr",
  storageBucket: "buidlr.appspot.com",
  messagingSenderId: "322905631520",
  appId: "1:322905631520:web:0e7a7e9b06577fec420545",
  measurementId: "G-TM6XWWKV7V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const storage = getStorage();

export function ProfileSettings() {
  const dispatch = useDispatch();
  const [img, setImg] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const { register, watch, handleSubmit, reset, getValues } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data) => {
    addDetailsToFirestore();
  };
  const owner = useSelector((state: RootState) => state.user.owner);

  useEffect(() => {
    reset({
      username: owner?.username ?? "",
      bio: owner?.bio ?? "",
      twitterHandle: owner?.twitterHandle ?? "",
    });
    getOwnerProfilePicture(owner).then((image) => {
      setImg(image);
    });
  }, [owner]);

  const addDetailsToFirestore = async () => {
    // create new object from owner which has all null or undefined values removed
    let ownerObject = Object.fromEntries(
      Object.entries(owner).filter(([_, v]) => v != null && v != undefined)
    );
    if (selectedImage != null) {
      let resizeImage = Resizer.imageFileResizer(
        selectedImage, // Is the file of the image which will resized.
        120, // Is the maxWidth of the resized new image.
        120, // Is the maxHeight of the resized new image.
        "JPEG", // Is the compressFormat of the resized new image.
        100, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (uri: File) => {
          setSelectedImage(uri);
          const storageRef = ref(storage, `pfp/${owner.address}`);
          uploadBytes(storageRef, uri).then((snapshot) => {
            // console.log("Uploaded a blob or file!");
          });
        }, // Is the callBack function of the resized new image URI.
        "file" // Is the output type of the resized new image.
      );
      ownerObject["pfp"] = true;
    }
    let newValue = 0;
    if (getValues("username") != null) {
      ownerObject["username"] = getValues("username");
      newValue++;
    }
    if (getValues("bio") != null) {
      ownerObject["bio"] = getValues("bio");
      newValue++;
    }
    if (getValues("twitterHandle") != null) {
      ownerObject["twitterHandle"] = getValues("twitterHandle");
      newValue++;
    }

    try {
      if (newValue > 0) {
        const docRef = await setDoc(
          doc(db, "holders", owner.address),
          ownerObject,
          { merge: true }
        ).then();
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    try {
      if (newValue > 0) {
        const docRef = await setDoc(
          doc(db, "buidlr-web", "holders"),
          {
            [owner.address]: ownerObject,
          },
          { merge: true }
        );
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    dispatch(setModalClose());
  };

  const useTwitter = watch("useTwitter");

  return (
    <form className={styles["form"]} onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <CircleLoader size="150px" color="#36d7b7" loading={isLoading} />
      ) : (
        <div>
          <div className={modalStyles["modal-buttons-row"]}>
            <div
              className={modalStyles["close-nav-button"]}
              id="right-button"
              onClick={() => {
                dispatch(setModalClose());
                // ModalHistory.openNext(dispatch)}
              }}
            >
              <RiCloseCircleFill size="30px" />
            </div>
          </div>
          <div>
            <label htmlFor="file-upload" className="custom-file-upload">
              {selectedImage != null ? (
                <img
                  className={styles["image"]}
                  alt="new image"
                  src={URL.createObjectURL(selectedImage)}
                />
              ) : owner?.pfp ? (
                img
              ) : (
                <MetaMaskAvatar address={owner?.address} size={50} />
              )}
              <AiOutlinePlusCircle color="white" size={"18px"} />
            </label>
            <input
              id="file-upload"
              type="file"
              {...register("image")}
              onChange={(event) => {
                setSelectedImage(event.target.files[0]);
              }}
            />
            <label className={styles["label"]} htmlFor="username">
              Username
            </label>
            <input
              maxLength={20}
              placeholder="Eyelord?"
              {...register("username")}
            />
          </div>
          <label className={styles["label"]}>Twitter handle or username</label>
          <input
            maxLength={20}
            placeholder="@your twitter handle"
            type="text"
            {...register("twitterHandle")}
          />

          <label className={styles["label"]} htmlFor="bio">
            Bio
          </label>
          <textarea
            // cols={106}
            maxLength={250}
            rows={6}
            placeholder=" Tell us a bit about your self"
            {...register("bio")}
          />

          <input type="submit" />
        </div>
      )}
    </form>
  );
}
